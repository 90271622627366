import React from 'react';
import PropTypes from 'prop-types';
import style from '../style.css';
import { liftTeal } from '../../../common/reportHelpers.js';

const incompleteBlocks = Array(10).fill('#edebe0');
const incompleteTan = '#edebe0';

const Blocks = ({ blocks, label, active }) => {
    return (
        <div>
            <div style={{ color: active ? liftTeal : incompleteTan }}>{label}</div>
            <div className={style.blocks}>
                {active
                    ? blocks.map((block, i) => {
                          return <div className={style.block} key={i} style={{ backgroundColor: block }} />;
                      })
                    : incompleteBlocks.map((block, i) => {
                          return <div className={style.block} key={i} style={{ backgroundColor: block }} />;
                      })}
            </div>
        </div>
    );
};

Blocks.propTypes = {
    blocks: PropTypes.array,
    label: PropTypes.string,
    active: PropTypes.bool,
};

export default Blocks;
