import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';

import Component from './component.js';
const Wrapper = props => {
    return (
        <Loader loading={props.loading} removeChildren>
            <Component {...props} />
        </Loader>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    // redux
    loading: PropTypes.bool,
};

export default Wrapper;
