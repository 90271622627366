import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import DefaultFooter from 'cccisd-footer';
import { Link } from 'cccisd-react-router';
import logo from '../../../images/CSRSW_Logo_Final_RGB_Full_Color.svg';

const Appdefs = window.cccisd.appDefs;
const Env = window.cccisd.env;
const wpUrl = Env.wpUrl || Appdefs.app.wpUrl;

const Footer = props => {
    const publicRoutes = [
        <a key="home" href={wpUrl}>
            Home
        </a>,
        <a key="services" href={wpUrl + '/index.php/our-services'}>
            Our Services
        </a>,
        <Link to="/interventions" key="interventions" highlight="interventions">
            On-Demand Courses
        </Link>,
        <a key="services" href={wpUrl + '/index.php/events'}>
            Events
        </a>,
        <Link to="/resources_public" key="resources" highlight="resources_public">
            Resources
        </Link>,
        <a key="about" href={wpUrl + '/index.php/about-us/'}>
            About Us
        </a>,
        <a key="cart" href={wpUrl + '/index.php/cart/'}>
            Cart
        </a>,
    ];

    return (
        <div className={style.footer}>
            <div className={style.topfooter}>
                <div className={props.className}>
                    <div className="row">
                        <div className="col-sm-8">
                            <div className={style.logo}>
                                <img src={logo} alt="Logo: Center For Safe and Resilient Schools and Workplaces" />
                            </div>
                            <h5>Training and Consultation Information</h5>
                            <address className={style.address}>
                                <p>
                                    <b>Contact:</b> Sydney Hutchins
                                </p>
                                <p>
                                    <b>Email:</b> info@traumaawareschools.org
                                </p>
                            </address>
                            <h5>Technical Assistance</h5>
                            <address className={style.address}>
                                <p>
                                    <b>Contact:</b> 3C Institute
                                </p>
                                <p>
                                    <b>Phone number:</b> (984) 316-0406
                                </p>
                                <p>
                                    <b>Email:</b> support@3cisd.com
                                </p>
                            </address>
                        </div>
                        <div className="col-sm-4">
                            <ul className={style.stacked}>
                                <div className={style.links}>{publicRoutes}</div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <DefaultFooter {...props} />
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
    showPolicyLinks: PropTypes.bool,
    copyrightYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Footer.defaultProps = {
    className: 'container',
    showPolicyLinks: true,
    copyrightYear: new Date().getFullYear(),
};

export default Footer;
