import React from 'react';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import PropTypes from 'prop-types';

const HorizontalBarChart = props => {
    const { color, total } = props;

    return (
        <div style={{ width: '350px', height: '105px' }}>
            <ResponsiveBar
                data={[
                    {
                        measure: '',
                        'STS total': total,
                    },
                ]}
                keys={['STS total']}
                indexBy="measure"
                colors={[color]}
                labelTextColor="#fff"
                legends={[]}
                maxValue={90}
                minValue={10}
                axisLeft={{
                    tickRotation: -90,
                    tickPadding: 5,
                }}
                markers={[
                    {
                        axis: 'x',
                        value: 17,
                        legend: 'None',
                        legendOffsetX: -15,
                        legendOffsetY: 80,
                    },
                    {
                        axis: 'x',
                        value: 85,
                        legend: 'Severe',
                        legendOffsetY: 80,
                        legendOffsetX: -20,
                    },
                ]}
                axisBottom={{
                    tickRotation: -45,
                }}
                layout="horizontal"
            />
        </div>
    );
};

HorizontalBarChart.propTypes = {
    total: PropTypes.number,
    color: PropTypes.string,
};

export default HorizontalBarChart;
