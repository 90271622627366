import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'stat_m2_risk_factors',
    label: 'STATM2RiskFactors',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
