import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'lift_c3_self_care_plan',
    label: 'LIFT_C3_Self_Care_Plan',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
