import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { ResponsiveLine } from 'cccisd-nivo/line';

import ReportHeader from '../../common/LIFT_Report_Header/index.js';
import style from './style.css';
import ReportFooter from '../../common/LIFT_Report_Footer/index.js';
import {
    activeChapters,
    renderAlert,
    liftTeal,
    liftRed,
    showDepressionLevel,
    showAnxietyLevel,
} from '../../common/reportHelpers.js';
import responsesQuery from './responses.graphql';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const Component = props => {
    const [loading, setLoading] = useState(true);
    const [hasModuleProgress, setHasModuleProgress] = useState(null);
    const [depressionChartSettings, setDepressionChartSettings] = useState({
        chapterScore: 0,
        chapterText: '',
        chapterLabel: '',
        chaptersData: [],
    });
    const [anxietyChartSettings, setAnxietyChartSettings] = useState({
        chapterScore: 0,
        chapterText: '',
        chapterLabel: '',
        chaptersData: [],
    });
    let chapterId = props.settings?.chapter || '1';

    useEffect(() => {
        getData();
    }, [chapterId]);

    async function getData() {
        const response = await client.query({
            query: responsesQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        const data = response.data.flows.assignmentProgress;

        if (Object.values(data).length > 0) {
            setHasModuleProgress(true);
        } else {
            setHasModuleProgress(false);
        }

        const chapterHandle = activeChapters[chapterId].id;

        let depressionData = [];
        let anxietyData = [];

        for (const [key, value] of Object.entries(data[chapterHandle])) {
            if (key.includes('Depression')) {
                depressionData.push({
                    y: value,
                    x: 'Chapter ' + key[7],
                });
            }
            if (key.includes('Anxiety')) {
                anxietyData.push({
                    y: value,
                    x: 'Chapter ' + key[7],
                });
            }
        }

        if (chapterId === '1') {
            depressionData = [...depressionData, { x: 'Chapter 4', y: null }, { x: 'Chapter 7', y: null }];
            anxietyData = [...anxietyData, { x: 'Chapter 4', y: null }, { x: 'Chapter 7', y: null }];
        }
        if (chapterId === '4') {
            depressionData = [...depressionData, { x: 'Chapter 7', y: null }];
            anxietyData = [...anxietyData, { x: 'Chapter 7', y: null }];
        }

        const chapterDepressionScore = Number(data[chapterHandle][`Chapter${chapterId}Depression`]);
        setDepressionChartSettings({
            chapterScore: chapterDepressionScore,
            chapterLabel: showDepressionLevel(chapterDepressionScore).label,
            chapterText: showDepressionLevel(chapterDepressionScore).text,
            chaptersData: depressionData,
        });

        const chapterAnxietyScore = Number(data[chapterHandle][`Chapter${chapterId}Anxiety`]);
        setAnxietyChartSettings({
            chapterScore: chapterAnxietyScore,
            chapterLabel: showAnxietyLevel(chapterAnxietyScore).label,
            chapterText: showAnxietyLevel(chapterAnxietyScore).text,
            chaptersData: anxietyData,
        });

        setLoading(false);
    }

    if (loading) {
        return <Loader loading type="inline" />;
    }

    if (!props.settings.chapter) {
        return renderAlert('Please select a Report Chapter in Report Settings');
    }

    return (
        <Report downloadFilename={`LIFT_Chapter_${chapterId}_${props.settings.chapterTitle}_Report`}>
            <Page>
                <div className={style.flexWrapper}>
                    <ReportHeader chapterId={chapterId} chapterTitle={props.settings.chapterTitle} />
                    {hasModuleProgress ? (
                        <div className={style.report}>
                            <div className={style.topic}>
                                <div className={style.reportScore}>
                                    <span style={{ color: liftTeal }}>{depressionChartSettings.chapterScore}/</span>
                                    30
                                </div>
                                <div className={style.label}>Depression</div>
                                <div className={style.text}>{depressionChartSettings.chapterText}</div>
                                <div style={{ height: '300px', marginTop: '25px' }}>
                                    <ResponsiveLine
                                        animate
                                        data={[
                                            {
                                                id: 'depression',
                                                data: depressionChartSettings.chaptersData,
                                            },
                                        ]}
                                        enablePointLabel
                                        colors={[liftTeal]}
                                        yScale={{
                                            max: 30,
                                            min: 0,
                                            type: 'linear',
                                        }}
                                        legends={[]}
                                        axisLeft={{
                                            legend: 'Depression',
                                            legendPosition: 'middle',
                                            legendOffset: -65,
                                            tickPadding: 30,
                                        }}
                                        margin={{
                                            top: 20,
                                            left: 80,
                                            right: 60,
                                            bottom: 60,
                                        }}
                                    />
                                </div>{' '}
                            </div>
                            <div className={style.topic}>
                                <div className={style.reportScore}>
                                    <span style={{ color: liftRed }}>{anxietyChartSettings.chapterScore}/</span>
                                    18
                                </div>
                                <div className={style.label}>Anxiety</div>
                                <div className={style.text}>{anxietyChartSettings.chapterText}</div>
                                <div style={{ height: '300px', marginTop: '25px' }}>
                                    <ResponsiveLine
                                        animate
                                        data={[
                                            {
                                                id: 'anxiety',
                                                data: anxietyChartSettings.chaptersData,
                                            },
                                        ]}
                                        enablePointLabel
                                        colors={[liftRed]}
                                        yScale={{
                                            max: 18,
                                            min: 0,
                                            type: 'linear',
                                        }}
                                        legends={[]}
                                        axisLeft={{
                                            legend: 'Anxiety',
                                            legendPosition: 'middle',
                                            legendOffset: -65,
                                            tickPadding: 30,
                                        }}
                                        margin={{
                                            top: 20,
                                            left: 80,
                                            right: 60,
                                            bottom: 60,
                                        }}
                                    />
                                </div>{' '}
                            </div>
                        </div>
                    ) : (
                        renderAlert('No events were selected yet.')
                    )}
                    <ReportFooter />
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
};

export default Component;
