import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import ReportHeader from '../../common/LIFT_Report_Header/index.js';
import style from './style.css';
import ReportFooter from '../../common/LIFT_Report_Footer/index.js';
import { renderAlert } from '../../common/reportHelpers.js';
import responsesQuery from './responses.graphql';
import thermo from '../../images/LIFT/thermo.png';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const Component = props => {
    const [loading, setLoading] = useState(true);
    const [hasModuleProgress, setHasModuleProgress] = useState(null);
    const [reportSettings, setReportSettings] = useState({});

    const reportTitle = props.settings?.title || "Traumatic Things I'm Avoiding";

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        const response = await client.query({
            query: responsesQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        const bother = response.data.flows.assignmentProgress.bother;
        const avoid = response.data.flows.assignmentProgress.avoid;
        const responses = {};

        if (reportTitle === "Traumatic Things I'm Avoiding") {
            for (const [key, value] of Object.entries(bother)) {
                if (value && key.includes('item1')) {
                    const rank = key.slice(0, -5);
                    const rankValue = rank[rank.length - 1];

                    responses[rankValue] = value;
                }
            }
        } else {
            for (const [key, value] of Object.entries(avoid)) {
                if (value && key.includes('item1')) {
                    const rank = key.slice(0, -5);
                    const rankValue = rank[rank.length - 1];

                    responses[rankValue] = value;
                }
            }
        }

        if (Object.keys(responses).length > 0) {
            setHasModuleProgress(true);
        } else {
            setHasModuleProgress(false);
        }

        setReportSettings(responses);

        setLoading(false);
    }

    if (loading) {
        return <Loader loading type="inline" />;
    }

    if (!props.settings?.title) {
        return renderAlert('Please select a Report Title in Report Settings');
    }

    return (
        <Report downloadFilename={`LIFT_Chapter_4_${reportTitle}_Report`}>
            <Page>
                <div className={style.flexWrapper}>
                    <ReportHeader chapterId="4" chapterTitle={reportTitle} />
                    {hasModuleProgress ? (
                        <>
                            <div className={style.introText}>
                                <div style={{ marginTop: '1em' }}>
                                    This stress thermometer lists some things you said you&apos;ve been avoiding and the
                                    rating you gave each item.
                                </div>
                            </div>
                            <div className={style.body}>
                                <img src={thermo} alt="thermometer" className={style.thermo} />
                                <div className={style.responses}>
                                    <div>
                                        <strong className={style.rating}>10</strong>
                                        {reportSettings['0']}
                                    </div>
                                    <div>
                                        <strong className={style.rating}>9</strong>
                                        {reportSettings['9']}
                                    </div>
                                    <div>
                                        <strong className={style.rating}>8</strong>
                                        {reportSettings['8']}
                                    </div>
                                    <div>
                                        <strong className={style.rating}>7</strong>
                                        {reportSettings['7']}
                                    </div>
                                    <div>
                                        <strong className={style.rating}>6</strong>
                                        {reportSettings['6']}
                                    </div>
                                    <div>
                                        <strong className={style.rating}>5</strong>
                                        {reportSettings['5']}
                                    </div>
                                    <div>
                                        <strong className={style.rating}>4</strong>
                                        {reportSettings['4']}
                                    </div>
                                    <div>
                                        <strong className={style.rating}>3</strong>
                                        {reportSettings['3']}
                                    </div>
                                    <div>
                                        <strong className={style.rating}>2</strong>
                                        {reportSettings['2']}
                                    </div>
                                    <div>
                                        <strong className={style.rating}>1</strong>
                                        {reportSettings['1']}
                                    </div>
                                </div>
                            </div>
                            <ReportFooter />
                        </>
                    ) : (
                        renderAlert('No responses were recorded.')
                    )}
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
};

export default Component;
