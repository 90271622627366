import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'lift_traumatic_dynamic',
    label: 'LIFT_Traumatic_Dynamic',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
