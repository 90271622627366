import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'stat_m3_my_stat_level_timepoints',
    label: 'STATM3MySTATLevelTimepoints',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
