import React from 'react';
import { Field, CccisdInput } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    moduleId: 'Module 5',
    moduleTitle: 'Self-Care',
};

const Component = props => {
    return (
        <div>
            <Field name="moduleId" component={CccisdInput} label="Module ID" />
            <Field name="moduleTitle" component={CccisdInput} label="Module Title" />
        </div>
    );
};

// widgetBuilder is a Formik Wrapper.
export default widgetBuilder({ initialValues })(Component);
