import React from 'react';
import PropTypes from 'prop-types';

const ReportKey = props => {
    return <h1>ReportKey</h1>;
};

ReportKey.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
};

export default ReportKey;
