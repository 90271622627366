import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import ReportHeader from '../../common/LIFT_Report_Header/index.js';
import style from './style.css';
import ReportFooter from '../../common/LIFT_Report_Footer/index.js';
import { neverThinkMap, oftenThinkMap, shortenResponse, renderAlert } from '../../common/reportHelpers.js';
import responsesQuery from './responses.graphql';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const MAX_RESPONSE_LENGTH = 75;

const Component = props => {
    const [loading, setLoading] = useState(true);
    const [hasModuleProgress, setHasModuleProgress] = useState(null);
    const [often, setOften] = useState([]);
    const [never, setNever] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        const response = await client.query({
            query: responsesQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        const data = response.data.flows.assignmentProgress;

        const oftenResponses = [];
        const neverResponses = [];

        for (const [key, value] of Object.entries(data.scores)) {
            if (value === '1') {
                if (key.includes('Often')) {
                    const { label, helpfulDevTag } = oftenThinkMap[key];

                    oftenResponses.push({
                        helpful: shortenResponse(data.helpful[helpfulDevTag], MAX_RESPONSE_LENGTH),
                        label,
                    });
                }
                if (key.includes('Never') || key.includes('Calming')) {
                    const { label, helpfulDevTag } = neverThinkMap[key];

                    neverResponses.push({
                        helpful: shortenResponse(data.helpful[helpfulDevTag], MAX_RESPONSE_LENGTH),
                        label,
                    });
                }
            }
        }

        if (oftenResponses.length > 1 || neverResponses.length > 1) {
            setHasModuleProgress(true);
        } else {
            setHasModuleProgress(false);
        }

        setOften(oftenResponses);
        setNever(neverResponses);
        setLoading(false);
    }

    if (loading) {
        return <Loader loading type="inline" />;
    }

    return (
        <Report downloadFilename={`LIFT_Chapter_3_${props.settings.chapterTitle}_Report`}>
            <Page>
                <div className={style.flexWrapper}>
                    <ReportHeader chapterId="3" chapterTitle={props.settings.chapterTitle} />
                    {hasModuleProgress ? (
                        <>
                            <div className={style.container}>
                                <div className={style.left}>
                                    <div className={style.topLeft}>
                                        {often.length > 0 && (
                                            <div className={style.leftLabel}>
                                                You said you <strong>often</strong> think
                                            </div>
                                        )}
                                        {often.map((o, i) => {
                                            return (
                                                <div key={i} className={style.leftResponses}>
                                                    {o.label}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={style.bottomLeft}>
                                        {never.length > 0 && (
                                            <div className={style.leftLabel}>
                                                You said you <strong>never</strong> think
                                            </div>
                                        )}
                                        {never.map((o, i) => {
                                            return (
                                                <div key={i} className={style.leftResponses}>
                                                    {o.label}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className={style.right}>
                                    <div className={style.topRight}>
                                        {often.length > 0 && <div className={style.rightLabel}>Helpful Thoughts</div>}
                                        {often.map((o, i) => {
                                            return (
                                                <div key={i} className={style.rightResponses}>
                                                    {o.helpful}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={style.bottomRight}>
                                        {never.length > 0 && <div className={style.rightLabel}>Helpful Thoughts</div>}
                                        {never.map((n, i) => {
                                            return (
                                                <div key={i} className={style.rightResponses}>
                                                    {n.helpful}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <ReportFooter />
                        </>
                    ) : (
                        renderAlert('No responses were recorded yet.')
                    )}
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
};

export default Component;
