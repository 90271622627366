import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'lift_dep_anx_dynamic',
    label: 'LIFT_Dep_Anx_Dynamic',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
