import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import _find from 'lodash/find';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { useParams, Redirect, Link } from 'cccisd-react-router';
import deploymentQuery from './deployment.graphql';

const AppDefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const ProductPage = () => {
    const { productHandle } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [deploymentHash, setDeploymentHash] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    const homepageRoute = _find(AppDefs.routes, r => r.handle === AppDefs.navs[0].homepage);

    useEffect(() => {
        async function getDeploymentId() {
            const resp = await client.query({
                query: deploymentQuery,
                variables: { productHandle },
                fetchPolicy: 'network-only',
            });

            const hash = _get(resp, 'data.flows.deployment.hash', null);
            if (!hash) {
                // set error
                setErrorMsg(`Invalid deployment handle "${productHandle}"`);
                setIsLoading(false);
            } else {
                setDeploymentHash(hash);
                setErrorMsg(null);
                setIsLoading(false);
            }
        }

        getDeploymentId();
    }, [productHandle]);

    if (isLoading) {
        return (
            <div style={{ textAlign: 'center' }}>
                <Loader loading type="inline" />
            </div>
        );
    }

    if (errorMsg) {
        return (
            <div>
                <div className="alert alert-danger">
                    <p>{errorMsg}</p>
                </div>
                {homepageRoute && (
                    <Link to={homepageRoute.url}>
                        <button type="button" className="btn btn-primary">
                            Go To Home
                        </button>
                    </Link>
                )}
            </div>
        );
    }

    // don't look for respondent hash until user is logged in
    if (!Fortress.auth()) {
        return <Redirect to={`/register?redirectUrl=${Boilerplate.url(`/product/${productHandle}`)}`} />;
    }

    return <Redirect to={`/d/${deploymentHash}/${Fortress.user.acting.respondent_hash}`} />;
};

export default ProductPage;
