import React from 'react';
import PropTypes from 'prop-types';

import style from './style.css';
import bar from '../../images/STAT/headerBar.png';
import logo from '../../images/STAT/logo.png';

const ReportHeader = props => {
    const { moduleId, moduleTitle } = props;

    return (
        <div className={style.container}>
            <div className={style.top}>
                <img className={style.logo} src={logo} alt="STAT logo" />
                <div className={style.reportDetails}>
                    <div className={style.moduleId}>{moduleId}</div>
                    <div className={style.moduleTitle}>{moduleTitle}</div>
                </div>
            </div>
            <img className={style.bar} src={bar} alt="STAT hand bar" />
        </div>
    );
};

ReportHeader.propTypes = {
    moduleId: PropTypes.string,
    moduleTitle: PropTypes.string,
};

export default ReportHeader;
