import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import widgets from '../widgets/widgets.js';
import ReportHeader from '../../common/STAT_Report_Header/index.js';
import style from './style.css';
import ReportFooter from '../../common/STAT_Report_Footer/index.js';
import responsesQuery from './responses.graphql';
import { renderAlert, shortenResponse } from '../../common/reportHelpers.js';
import sImage from '../../images/STAT/m5/s.png';
import mImage from '../../images/STAT/m5/m.png';
import aImage from '../../images/STAT/m5/a.png';
import rImage from '../../images/STAT/m5/r.png';
import tImage from '../../images/STAT/m5/t.png';
import banner from '../../images/STAT/m5/banner.png';

import IconPushpin from 'cccisd-icons/pushpin';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const SMARTGOAL_MAX_CHAR_LENGTH = 170;
const PAGE_1_MAX_CHAR_LENGTH = 200;
const PAGE_2_MAX_CHAR_LENGTH = 160;

const Component = props => {
    const [reportSettings, setReportSettings] = useState({
        smart: '',
        barriers: [],
        solutions: [],
    });
    const [letters, setLetters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasModuleProgress, setHasModuleProgress] = useState(null);

    const {
        widgets: {
            reportTitle: {
                props: {
                    settings: { moduleId, moduleTitle },
                },
            },
        },
    } = props;

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        const response = await client.query({
            query: responsesQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        const data = response.data.flows.assignmentProgress.devTags;
        const dataLists = response.data.flows.assignmentProgress.devTagsLists;

        if (!data.SMARTGoal) {
            setHasModuleProgress(false);
        }

        if (data.SMARTGoal) {
            setHasModuleProgress(true);

            const barrierList = shortenResponseList(dataLists.GoalBarriers, PAGE_2_MAX_CHAR_LENGTH);
            const solutionList = shortenResponseList(dataLists.GoalSolutions, PAGE_2_MAX_CHAR_LENGTH);

            setReportSettings({
                smart: shortenResponse(data.SMARTGoal, SMARTGOAL_MAX_CHAR_LENGTH),
                barriers: barrierList,
                solutions: solutionList,
            });
        }

        const letterSettings = [
            {
                id: 'Specific',
                label: 'Is your goal specific?',
                response: shortenResponse(data.SpecificGoal, PAGE_1_MAX_CHAR_LENGTH),
                image: <img src={sImage} alt="S for Specific" className={style.letter} />,
            },
            {
                id: 'Measurable',
                label: 'Can you measure your progress toward meeting your goal?',
                response: shortenResponse(data.MeasurableGoal, PAGE_1_MAX_CHAR_LENGTH),
                image: <img src={mImage} alt="M for Measurable" className={style.letter} />,
            },
            {
                id: 'Achievable',
                label: 'Can you achieve your goal? Are the barriers to reaching your goal surmountable?',
                response: shortenResponse(data.AchievableGoal, PAGE_1_MAX_CHAR_LENGTH),
                image: <img src={aImage} alt="A for Achievable" className={style.letter} />,
            },
            {
                id: 'Realistic',
                label: 'Is your goal realistic?',
                response: shortenResponse(data.RealisticGoal, PAGE_1_MAX_CHAR_LENGTH),
                image: <img src={rImage} alt="R for Realistic" className={style.letter} />,
            },
            {
                id: 'Timely',
                label: 'What is the timeframe for your goal?',
                response: shortenResponse(data.TimelyGoal, PAGE_1_MAX_CHAR_LENGTH),
                image: <img src={tImage} alt="T for Timely" className={style.letter} />,
            },
        ];
        setLetters(letterSettings);

        setLoading(false);
    }

    const shortenResponseList = (respArray, charLength) => {
        if (!respArray) {
            return [];
        }
        return respArray
            .filter(r => r)
            .map(r => {
                if (r.length > charLength) {
                    let trimmedString = r.substring(0, charLength);

                    trimmedString = trimmedString.substr(
                        0,
                        Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
                    );
                    return trimmedString + '...';
                }
                return r;
            });
    };

    const postItNote = (
        <>
            <div className={style.note}>
                <div>My Self-Care Plan</div>
            </div>
            <div className={style.pushpin}>
                <IconPushpin spaceRight />
            </div>
        </>
    );

    if (loading) {
        return <Loader loading type="inline" />;
    }

    return (
        <div>
            <Report height="600px" downloadFilename="STAT_Module_5_Report">
                <Page>
                    <div className={style.flexWrapper}>
                        <ReportHeader moduleId={moduleId} moduleTitle={moduleTitle} />
                        {hasModuleProgress ? (
                            <div className={style.reportBody} id={style.page1body}>
                                {postItNote}
                                <div className={style.goal}>{reportSettings.smart}</div>
                                <div>
                                    {letters.map(({ id, image, label, response }) => {
                                        return (
                                            <div key={id} className={style.responseContainer}>
                                                <div className={style.label}>
                                                    <span style={{ fontWeight: 'bold', marginRight: '1em' }}>{id}</span>
                                                    {label}
                                                </div>
                                                <div className={style.content}>
                                                    {image} <div className={style.response}>{response}</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            renderAlert('Please add a SMART Goal to see this report')
                        )}
                        <ReportFooter />
                    </div>
                </Page>
                <Page>
                    <div className={style.flexWrapper}>
                        <ReportHeader moduleId={moduleId} moduleTitle={moduleTitle} />
                        {hasModuleProgress ? (
                            <div className={style.reportBody}>
                                {postItNote}
                                <img src={banner} alt="SMART banner" className={style.banner} />
                                <div id={style.page2body}>
                                    <h4>
                                        List the barriers to meeting your goal and some strategies for overcoming those
                                        barriers.
                                    </h4>
                                    <div className={style.label}>Barriers:</div>
                                    <ul className={style.page2response}>
                                        {reportSettings.barriers.map((item, i) => {
                                            return <li key={i}>{item}</li>;
                                        })}
                                    </ul>
                                    <div className={style.label}>Strategies:</div>
                                    <ul className={style.page2response} style={{ marginBottom: '6em' }}>
                                        {reportSettings.solutions.map((item, i) => {
                                            return <li key={i}>{item}</li>;
                                        })}
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            renderAlert('Please add a SMART Goal to see this report')
                        )}
                        <ReportFooter />
                    </div>
                </Page>
            </Report>
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    widgetsLoaded: PropTypes.array,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
})(Component);
