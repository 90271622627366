import React from 'react';
import style from './style.css';

const ReportFooter = () => {
    return (
        <div className={style.container}>
            <div>A Product of RAND &copy;{new Date().getFullYear()}</div>
            <div>3C Institute </div>
        </div>
    );
};

export default ReportFooter;
