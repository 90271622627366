import { reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import widgets from '../widgets/widgets.js';

export const initialValues = {
    widgets: widgets.reduce((obj, w) => {
        obj[w.handle] = w.builderInitialValues;
        return obj;
    }, {}),
};

const Component = () => {
    return null;
};

// reportTemplateBuilder is a Formik wrapper which combines the Fields in this component with Filter and Widget Fields.
export default reportTemplateBuilder({ widgets })(Component);
