import React from 'react';
import Loader from 'cccisd-loader';

import Component from './component.js';

const Wrapper = props => {
    return (
        <Loader loading={props.loading} removeChildren>
            <Component {...props} />
        </Loader>
    );
};

export default Wrapper;
