import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import ReportHeader from '../../common/LIFT_Report_Header/index.js';
import style from './style.css';
import ReportFooter from '../../common/LIFT_Report_Footer/index.js';
import responsesQuery from './responses.graphql';
import { shortenResponse } from '../../common/reportHelpers.js';

import IconHourglass from 'cccisd-icons/hour-glass';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;
const MAX_RESPONSE_LENGTH = 635;

const Component = props => {
    const [loading, setLoading] = useState(true);
    const [hasModuleProgress, setHasModuleProgress] = useState(null);
    const [reportSettings, setReportSettings] = useState('');

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        const response = await client.query({
            query: responsesQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        const data = response.data.flows.assignmentProgress.devTags.Chapter3SelfCarePlan;

        setReportSettings(shortenResponse(data, MAX_RESPONSE_LENGTH));

        if (data === null) {
            setHasModuleProgress(false);
        } else {
            setHasModuleProgress(true);
        }

        setLoading(false);
    }

    if (loading) {
        return <Loader loading type="inline" />;
    }

    return (
        <Report downloadFilename={`LIFT_Chapter_3_${props.settings.chapterTitle}_Report`}>
            <Page>
                <div className={style.flexWrapper}>
                    <ReportHeader chapterId="3" chapterTitle={props.settings.chapterTitle} />
                    <div className={style.container}>
                        <div>
                            {`Now that you've learned how to replace negative thoughts with helpful ones, spend a few days, or
                        even a week, practicing this as well as the relaxation exercises and fun activities from your
                        Self-Care Plan. You can use the worksheet below to record any negative thoughts you have and
                        come up with helpful thoughts.`}
                        </div>
                        <div>
                            <h4>Your Personal Self-Care Plan:</h4>
                            <div className={style.response}>
                                {hasModuleProgress ? (
                                    reportSettings
                                ) : (
                                    <div className="alert alert-info" style={{ textAlign: 'center', marginTop: '1em' }}>
                                        <IconHourglass spaceRight />
                                        No responses were recorded yet
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <h4>Real-Life Practice: Replacing Negative Thoughts</h4>
                            <div>
                                Print this worksheet, and when something stressful happens, recognize your negative
                                thoughts and write a few of them in the first column. Then write helpful thoughts to
                                replace the negative ones. If you have these negative thoughts often, use some of the
                                helpful thoughts you came up with in this course to argue against them.
                            </div>
                        </div>
                        <div className={style.thoughts}>
                            <div className={style.label}>
                                My Negative Thoughts
                                <div>
                                    {new Array(6).fill(' ').map((_, i) => {
                                        return <div key={i} className={style.emptyBox} />;
                                    })}
                                </div>
                            </div>
                            <div className={style.label}>
                                My Helpful Thoughts
                                <div>
                                    {new Array(6).fill(' ').map((_, i) => {
                                        return <div key={i} className={style.emptyBox} />;
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ReportFooter />
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
};

export default Component;
