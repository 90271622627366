import React from 'react';
import { Field, CccisdWysiwyg } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    reportIntro:
        'You indicated that the factors listed below may be putting you at higher risk for developing symptoms of STS. Through awareness and action, you can effectively address these risk factors, allowing you to be the teacher you want to be.',
};

const Component = props => {
    return (
        <div>
            <Field name="reportIntro" component={CccisdWysiwyg} label="Report Intro" />
        </div>
    );
};

// widgetBuilder is a Formik Wrapper.
export default widgetBuilder({ initialValues })(Component);
