import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import ReportHeader from '../../common/LIFT_Report_Header/index.js';
import style from './style.css';
import ReportFooter from '../../common/LIFT_Report_Footer/index.js';
import {
    renderAlert,
    getSelectedEvents,
    getStressLabel,
    makeTealBlocks,
    liftTeal,
} from '../../common/reportHelpers.js';
import responsesQuery from './responses.graphql';
import Blocks from './Blocks/index.js';

import IconCheck from 'cccisd-icons/checkbox-checked.js';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const Component = props => {
    const [loading, setLoading] = useState(true);
    const [hasModuleProgress, setHasModuleProgress] = useState(null);
    const [reportSettings, setReportSettings] = useState({
        events: [],
        eventsTotal: 0,
        level: 0,
        label: '',
    });
    const [chapterBlocks, setChapterBlocks] = useState({
        chapter1: [],
        chapter4: [],
        chapter7: [],
    });

    let chapterId = props.settings?.chapter || '1';
    useEffect(() => {
        getData();
    }, [chapterId]);

    async function getData() {
        const response = await client.query({
            query: responsesQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        const data = response.data.flows.assignmentProgress['chapter' + chapterId];

        const answers = getSelectedEvents(data, chapterId);

        if (answers.length > 0) {
            setHasModuleProgress(true);
        } else {
            setHasModuleProgress(false);
        }

        const chapterEventTotal = Number(data[`Chapter${chapterId}StressfulEvents`]);
        const chapterLevelTotal = Number(data[`Chapter${chapterId}StressLevel`]);

        setReportSettings({
            events: answers,
            eventsTotal: chapterEventTotal,
            level: chapterLevelTotal,
            label: getStressLabel(chapterLevelTotal),
        });

        const levelTotal1 = Number(response.data.flows.assignmentProgress.chapter1.Chapter1StressLevel);
        const levelTotal4 = Number(response.data.flows.assignmentProgress.chapter4.Chapter4StressLevel);
        const levelTotal7 = Number(response.data.flows.assignmentProgress.chapter7.Chapter7StressLevel);
        setChapterBlocks({
            chapter1: makeTealBlocks(levelTotal1),
            chapter4: makeTealBlocks(levelTotal4),
            chapter7: makeTealBlocks(levelTotal7),
        });

        setLoading(false);
    }

    if (loading) {
        return <Loader loading type="inline" />;
    }

    if (!props.settings.chapter) {
        return renderAlert('Please select a Report Chapter in Report Settings');
    }

    return (
        <Report downloadFilename={`LIFT_Chapter_${chapterId}_${props.settings.chapterTitle}_Report`}>
            <Page>
                <div className={style.flexWrapper}>
                    <ReportHeader chapterId={chapterId} chapterTitle={props.settings.chapterTitle} />
                    <div>
                        {hasModuleProgress ? (
                            <div>
                                <div className={style.top}>
                                    <div className={style.label}>
                                        Your <span style={{ color: liftTeal }}>stress</span> level is{' '}
                                        {reportSettings.label}
                                    </div>
                                    <div className={style.score}>
                                        <div>{reportSettings.level}</div>
                                        <div className={style.baseline}>10</div>
                                    </div>
                                    <div className={style.graphs}>
                                        <Blocks
                                            key={7}
                                            blocks={chapterBlocks.chapter7}
                                            label="Chapter 7"
                                            active={chapterId === '7'}
                                        />
                                        <Blocks
                                            key={4}
                                            blocks={chapterBlocks.chapter4}
                                            label="Chapter 4"
                                            active={chapterId === '4' || chapterId === '7'}
                                        />
                                        <Blocks id={1} blocks={chapterBlocks.chapter1} label="Chapter 1" active />
                                    </div>
                                </div>
                                <div className={style.middle}>
                                    You said <span className={style.middleText}>{reportSettings.eventsTotal}</span>{' '}
                                    things have happened to you and that your stress level is{' '}
                                    <span className={style.middleText}>{reportSettings.label}</span>.
                                </div>
                                <div className={style.bottom}>
                                    {reportSettings.events.map((e, i) => {
                                        return (
                                            <div key={i} className={style.event}>
                                                <span style={{ color: liftTeal, fontSize: '2.25em' }}>
                                                    <IconCheck spaceRight />
                                                </span>
                                                {e}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            renderAlert('No events were selected yet.')
                        )}
                    </div>
                    <ReportFooter />
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
};

export default Component;
