import React from 'react';
import { ResourceCenter } from 'cccisd-laravel-resources';
import style from './style.css';

const Interventions = () => {
    function renderResourceTiles(resources, renderDefault) {
        return <div className={style.interventionsList}>{renderDefault(resources)}</div>;
    }
    return (
        <ResourceCenter
            handle="interventions"
            key="interventions"
            renderResourceTiles={renderResourceTiles}
        />
    );
};

export default Interventions;
