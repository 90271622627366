import React from 'react';
import { Field, CccisdSelect } from 'cccisd-formik';
import { reportTemplateBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    chapterTitle: "Traumatic Things I'm Avoiding",
};

const Component = () => {
    const titles = [
        {
            value: '',
            label: '-- Choose Report Title --',
        },
        {
            value: "Traumatic Things I'm Avoiding",
            label: "Traumatic Things I'm Avoiding",
        },
        {
            value: "Things I'm Avoiding",
            label: "Things I'm Avoiding",
        },
    ];

    return (
        <div>
            <Field name="title" component={CccisdSelect} label="Report Title" options={titles} />
        </div>
    );
};

export default reportTemplateBuilder({})(Component);
