import React from 'react';
import { Field, CccisdInput } from 'cccisd-formik';
import { reportTemplateBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    chapterTitle: 'Negative & Positive Thoughts',
};

const Component = () => {
    return (
        <div>
            <Field name="chapterTitle" component={CccisdInput} label="Chapter Title" />
        </div>
    );
};

export default reportTemplateBuilder({})(Component);
