import React from 'react';
import PropTypes from 'prop-types';

const ReportIntro = props => {
    return <h1>ReportIntro</h1>;
};

ReportIntro.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
};

export default ReportIntro;
