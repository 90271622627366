import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'reportIntro',
    label: 'Report Intro',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
