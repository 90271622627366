import React from 'react';
import { Field, CccisdSelect, CccisdInput } from 'cccisd-formik';
import { reportTemplateBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    chapterTitle: 'Trauma-Related Problems',
};

const Component = () => {
    const chapters = [
        { value: '', label: '-- Choose Chapter Report --' },
        { value: 1, label: 'Chapter 1' },
        { value: 4, label: 'Chapter 4' },
        { value: 7, label: 'Chapter 7' },
    ];

    return (
        <div>
            <Field name="chapter" component={CccisdSelect} label="Chapter Report" options={chapters} />
            <Field name="chapterTitle" component={CccisdInput} label="Chapter Title" />
        </div>
    );
};

export default reportTemplateBuilder({})(Component);
