import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'stat_m5_smart_goal',
    label: 'STATM5SMARTgoal',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
