import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'lift_c4_trauma_thermometer',
    label: 'LIFT_C4_TraumaThermometer',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
