import React from 'react';
import { RegisterLoginPage } from 'cccisd-laravel-nexus';
import { useLocation, Redirect } from 'cccisd-react-router';

const Register = () => {
    const { pathname, search } = useLocation();
    if (!search && pathname.startsWith('/licensing/voucher/redeem')) {
        return <Redirect to={pathname + `?redirectUrl=${pathname}`} />;
    }

    const registerLoginProps = {
        registerProps: {
            role: 'learner',
            showPasswordFields: true,
            title: 'Register',
            validateAdditionalFields: values => {
                let errors = {};
                if (!values.first_name) {
                    errors.first_name = 'First name is required.';
                }
                if (!values.last_name) {
                    errors.last_name = 'Last name is required.';
                }
                return errors;
            },
        },
        loginProps: { title: 'Login' },
    };

    return <RegisterLoginPage {...registerLoginProps} />;
};

export default Register;
